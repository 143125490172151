exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acetylcholine-js": () => import("./../../../src/pages/acetylcholine.js" /* webpackChunkName: "component---src-pages-acetylcholine-js" */),
  "component---src-pages-dopamine-js": () => import("./../../../src/pages/dopamine.js" /* webpackChunkName: "component---src-pages-dopamine-js" */),
  "component---src-pages-histamine-js": () => import("./../../../src/pages/histamine.js" /* webpackChunkName: "component---src-pages-histamine-js" */),
  "component---src-pages-hypocretin-js": () => import("./../../../src/pages/hypocretin.js" /* webpackChunkName: "component---src-pages-hypocretin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-norepinephrine-js": () => import("./../../../src/pages/norepinephrine.js" /* webpackChunkName: "component---src-pages-norepinephrine-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-serotonin-js": () => import("./../../../src/pages/serotonin.js" /* webpackChunkName: "component---src-pages-serotonin-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-shared-js": () => import("./../../../src/pages/shared.js" /* webpackChunkName: "component---src-pages-shared-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

